<template>
  <div>
    <!-- ======= MAIN ======= -->
    <main id="main">
      <!-- ======= BAŞLIK ======= -->
      <section class="intro-single">
        <div class="container">
          <div class="row">
            <div class="col-md-12 col-lg-8">
              <div class="title-single-box">
                <h1 class="title-single">Birimler</h1>
                <span class="color-text-b"
                  >{{ Belediyebilgiler.belediyeadi }} BELEDİYE BAŞKANLIĞI</span
                >
              </div>
            </div>
            <div class="col-md-12 col-lg-4">
              <nav
                aria-label="breadcrumb"
                class="breadcrumb-box d-flex justify-content-lg-end"
              >
                <ol class="breadcrumb">
                  <li class="breadcrumb-item">
                    <a href="/">Anasayfa</a>
                  </li>
                  <li class="breadcrumb-item active" aria-current="page">
                    Birimler
                  </li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- =======  REHBER ======= -->

      <!-- ======= BİRİMLER ======= -->
      <section class="property-grid grid">
        <div class="container">
          <div class="row">
            <div class="col-xl-9">
              <div class="row">
                <div
                  v-for="(birim, index) in Birimler.slice(0, 15)"
                  :key="index"
                  class="col-lg-4"
                >
                  <div class="card-box-c foo">
                    <div class="card-header-c d-flex">
                      <div
                        style="padding: 1rem 10rem 1rem 2.5rem"
                        class="card-box-ico"
                      >
                        <span
                          style="font-size: 5rem"
                          class="bi bi-chevron-right"
                        ></span>
                      </div>
                      <div class="card-title-c align-self-center">
                        <a @click="detayagit(birim)" class="link-c link-icon">
                          <h2
                            style="margin-left: -150px; font-size: 1.5rem"
                            class="title-c"
                          >
                            {{ birim.birimadi }}
                          </h2>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Sidebar />
          </div>
          <div class="row">
            <div class="col-sm-9">
              <nav class="pagination-a">
                <ul class="pagination justify-content-end">
                  <li class="page-item disabled">
                    <a class="page-link" href="#" tabindex="-1">
                      <span class="bi bi-chevron-left"></span>
                    </a>
                  </li>
                  <li class="page-item active">
                    <a class="page-link" href="#">1</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">2</a>
                  </li>
                  <li class="page-item">
                    <a class="page-link" href="#">3</a>
                  </li>
                  <li class="page-item next">
                    <a class="page-link" href="#">
                      <span class="bi bi-chevron-right"></span>
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          </div>
        </div>
      </section>
      <!-- =======  BİRİMLER ======= -->
    </main>
    <!-- ======= MAIN ======= -->
  </div>
</template>

<script>
import Sidebar from "@/components/Sidebar.vue";
export default {
  name: "Sehitler",
  data() {
    return {
      Birimler: {},
      Belediyebilgiler: {},
    };
  },
  methods: {
    detayagit(birim) {
      const birimID = birim.birimId;
      this.$router.push("/BirimDetay/" + birimID);
    },
  },
  async created() {
    const domainAd = window.location.hostname;
    await this.$store.dispatch("domainbelirle", domainAd);
    await this.$store.dispatch("vericekme", "Birimler");
    await this.$store.dispatch("vericekme", "Genel");
    this.Belediyebilgiler = this.$store.getters.getBelgenel;
    const birimmap = this.$store.getters.getBirimler;
    this.Birimler = Object.values(birimmap);
    this.Birimler.sort(function (b, a) {
      return a.uyeId - b.uyeId;
    });
  },
  components: {
    Sidebar,
  },
};
</script>

<style scoped>
</style>
